@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-datepicker/dist/react-datepicker.css';

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

/* Annuler le padding-right ajouté par HeadlessUI */
html {
  padding-right: 0 !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background: white;
  color: black;
}

.react-datepicker__header {
  background-color: white;
  border: none;
  text-align: left;
}

.react-datepicker__current-month {
  text-decoration: none;
  text-transform: capitalize;
  padding-left: 8px;
}

.react-datepicker__navigation {
  top: 8px;
}

.react-datepicker__navigation--previous {
  right: 32px;
  left: auto;
}

/* checkbox */
input[type='checkbox']:checked,
input[type='checkbox']:checked + label div.checkbox {
  @apply bg-primary-500 border-primary-500;
}

input[type='checkbox']:checked + label div::after {
  content: '';
  display: block;
  position: absolute;
  left: 5px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.create-form > div:last-child {
  border-top: 0px solid transparent;
}

/* input */
@layer components {
  .catalog-input {
    @apply w-full h-10 rounded-xl border border-gray-200 shadow-sm flex items-center py-2 px-4 placeholder:text-gray-500 focus:border-primary-400 focus:ring-1 focus:ring-primary-400 focus:outline-none;
  }
}

/* Button styles */
@layer components {
  .btn {
    @apply inline-flex gap-2 rounded-full justify-center items-center text-sm font-semibold min-w-20;
  }

  .btn-lg {
    @apply px-5 h-12 leading-6;
  }
  .btn-md {
    @apply px-4 h-10 leading-5;
  }
  .btn-sm {
    @apply px-3 h-8  leading-5;
  }
  .btn-xs {
    @apply pr-5 h-6 leading-5;
  }

  .btn-primary {
    @apply text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-200 disabled:bg-primary-200 shadow-sm;
  }
  .btn-alert {
    @apply text-white bg-error-500 hover:bg-error-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-error-200 disabled:bg-error-200 shadow-sm;
  }
  .btn-success {
    @apply text-white bg-success-500 hover:bg-success-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-success-500 disabled:bg-success-200 shadow-sm;
  }
  .btn-secondary {
    @apply text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-50 disabled:bg-gray-50 shadow-sm;
  }
  .btn-tertiary {
    @apply text-gray-700 bg-white hover:bg-gray-50 border border-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-50 disabled:opacity-50 shadow-sm;
  }

  .btn-text-primary {
    @apply text-primary-500 hover:text-primary-600 focus:outline-none  disabled:text-primary-200;
  }
  .btn-text-alert {
    @apply text-error-500 hover:text-error-600 focus:outline-none disabled:text-error-200;
  }
  .btn-text-success {
    @apply text-success-500 hover:text-success-600 focus:outline-none  disabled:text-success-200;
  }
  .btn-text-secondary,
  .btn-text-tertiary {
    @apply text-gray-700 border border-gray-200 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-50 disabled:text-gray-50;
  }
}

@layer components {
  /* headings */
  .h1 {
    @apply text-2xl font-bold font-heading;
  }

  .h2 {
    @apply text-lg/6 font-bold font-heading;
  }

  /* homepage */
  .homepage-card {
    @apply p-5 border border-gray-200 rounded-xl flex flex-col gap-4;
  }

  .onboarding-progress-card {
    @apply p-3 border border-gray-200 rounded-xl flex flex-col gap-1;
  }
}

@layer components {
  /* alternate names modal */
  .alternate-names-modal {
    @apply sm:my-8 sm:p-6 sm:w-full relative transform overflow-hidden rounded-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:max-w-5xl;
  }

  .default-modal {
    @apply sm:my-8 sm:w-full sm:max-w-sm sm:p-6 relative min-w-[480px] transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all;
  }
}

@layer utilities {
  .smart-animate {
    @apply animate-smart-animate bg-clip-text text-transparent bg-gradient-to-r from-stone-400 via-gray-700 from-[30%] via-[50%] to-[70%] to-stone-400 bg-[length:300%_100%];
  }

  .fade-in-out {
    @apply transition-opacity duration-500 ease-in-out;
  }
}

/* react-doc-viewer */
#react-doc-viewer #proxy-renderer,
#react-doc-viewer #html-renderer,
#react-doc-viewer #txt-renderer,
#react-doc-viewer #bmp-renderer,
#react-doc-viewer #png-renderer,
#react-doc-viewer #jpg-renderer,
#react-doc-viewer #csv-renderer,
#react-doc-viewer #pdf-renderer,
#react-doc-viewer #webp-renderer,
#react-doc-viewer #msdoc-renderer {
  height: 100%;
}

@keyframes highlight {
  0% {
    box-shadow: 0 0 0 0 rgba(231, 85, 49, 0.5);
    border-radius: 12px;
  }
  70% {
    box-shadow: 0 0 0 10px rgba(231, 85, 49, 0);
    border-radius: 12px;
  }
  100% {
    box-shadow: 0 0 0 0 rgba(231, 85, 49, 0);
    border-radius: 12px;
  }
}

.animate-highlight {
  animation: highlight 3s ease-in-out;
}
